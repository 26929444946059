<template>
  <div>
    <v-card>
      <v-card-title class="px-2">
        <span>รายงานสินค้าคงเหลือ</span>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="exportExcel" :loading="exportLoading" :disabled="loading">ออกรายงาน</v-btn>
      </v-card-title>
      <v-row class='px-2 pb-2'>
        <v-col cols='12' md='6' lg='4'>
          <v-text-field v-model='textSearch'
                        filled
                        @input='getProducts'
                        label='ค้นหาสินค้า'
                        dense
                        hide-details
                        placeholder='คำค้นหา'>

          </v-text-field>
        </v-col>
        <v-col cols='12' md='6' lg='4'>
          <v-autocomplete
              v-model='storeSelection'
              filled
              :items='storeList'
              label='เลือกคลังสินค้า'
              item-value="store_id"
              item-text="store_name"
              @change="getProducts"
              dense
              hide-details
              placeholder='คำค้นหา'>
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-data-table
          :headers='headers'
          :items='dataList'
          :items-per-page='itemsPerPage'
          :loading='loading'
          no-data-text='ไม่มีข้อมูล !'
          hide-default-footer
          loading-text='กำลังโหลดข้อมูล...'
          :footer-props='footer'
          no-results-text='ไม่มีข้อมูล !'
          class='elevation-1'
      >
        <template #[`item.store_product_cost`]='{ item }'>
          <span>{{
              item.store_product_cost
            }}
            <CurrentCurrency v-if="item.store_product_price !=='รวม'"/>
          </span>
        </template>
        <template #[`item.store_product_price`]='{ item }'>
          <span :class="item.store_product_price ==='รวม'?'primary--text font-weight-bold':''">{{
              item.store_product_price
            }}
           <CurrentCurrency v-if="item.store_product_price !=='รวม'"/>
          </span>
        </template>
        <template #[`item.store_product_balance`]='{ item }'>
          <span :class="item.store_product_price ==='รวม'?'primary--text font-weight-bold':''">{{
              item.store_product_balance
            }} </span>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import useProductBalance from "@/views/report/useProductBalance";
import CurrentCurrency from "@/components/CurrentCurrency";

export default {
  props: {},
  components: {CurrentCurrency},
  setup(_, {root, emit}) {
    return {...useProductBalance()}
  },

}
</script>

<style scoped>

</style>

import { onMounted, ref, watch } from "@vue/composition-api";
import footerDataTables from "@/fake-db/footerDataTables.json";
import { api } from "@/services/api";
import { removeComma } from "@/services/filters"

export default function useProductBalance() {
    const textSearch = ref('')
    const XLSX = require('xlsx')
    const headers = ref([
        {
            text: '#',
            value: 'number',
            width: '70',
        },
        {
            text: 'รหัสสินค้า',
            value: 'product_code',
            width: '160',
        },
        {
            text: 'ชื่อสินค้า',
            value: 'product_name',
            width: '200',
        },
        {
            text: 'น้ำหนัก',
            value: 'product_weight',
            width: '130',
        },
        {
            text: 'หน่วย',
            value: 'product_unit',
            width: '30',
        },
        {
            text: 'ราคาทุน',
            value: 'store_product_cost',
            width: '130',
            align: 'end',
        },
        {
            text: 'ราคาขาย',
            value: 'store_product_price',
            width: '130',
            align: 'end',
        },
        {
            text: 'คงเหลือ',
            value: 'store_product_balance',
            width: '130',
            align: 'end',
        },

    ])
    const itemsPerPage = ref(-1)
    const status = ref('1')
    const dataList = ref([])
    const loading = ref(false)
    const isUpdateStatus = ref(false)
    const dataEdit = ref({})
    const footer = ref(footerDataTables)
    const isEdit = ref(false)
    const isAdd = ref(false)
    const isShowDetail = ref(false)
    const storeList = ref([])
    const storeSelection = ref('')
    const totalBalance = ref('')
    const exportLoading = ref(false)

    const getProducts = () => {
        loading.value = true
        api.get({
            path: '/reportProductBalance',
            param: `?store_id=${ storeSelection.value }&search=${ textSearch.value }&currency_id=${ localStorage.getItem('currencyId') }`,
        }).then(({ data, total }) => {
            totalBalance.value = total
            mapData(data)
            loading.value = false
        }).catch(error => {
            console.log('error :', error)
            loading.value = false
        })
    }

    const getStores = () => {
        api.get({
            path: '/manager/stores',
            param: `?search=${ textSearch.value }&active=1`,
        }).then(({ data }) => {
            storeList.value = data
            storeList.value.unshift({ store_id: '', store_name: 'ทั้งหมด' })
            storeSelection.value = data[0].store_id
            getProducts()
        }).catch(error => {
            console.log('error :', error)
        })
    }

    const mapData = (data) => {
        dataList.value = data.map((item, index) => {
            return {
                number: index + 1,
                ...item,
            }
        })
        dataList.value.push({
            store_product_price: 'รวม',
            store_product_balance: totalBalance.value,
        })

    }

    const exportExcel = async () => {
        exportLoading.value = true
        const fileName = `รายงานสินค้าคงเหลือ.xlsx`
        const Heading = [
            [`รายงานสินค้าคงเหลือ`],
            [
                '#',
                `รหัสสินค้า`,
                'ชื่อสินค้า',
                `น้ำหนัก`,
                'หน่วย',
                `ราคาทุน`,
                'ราคาขาย',
                `คงเหลือ`,
            ],
        ]

        const ws = XLSX.utils.aoa_to_sheet(Heading)

        const dataExport = await removeComma(JSON.parse(JSON.stringify(dataList.value)))
        XLSX.utils.sheet_add_json(ws, dataExport, {
            header: [
                'number',
                'product_code',
                'product_name',
                'product_weight',
                'product_unit',
                'store_product_cost',
                'store_product_price',
                'store_product_balance',

            ],
            skipHeader: true,
            origin: -1,
        })
        const wb = XLSX.utils.book_new()
        const merge = [
            { s: { r: 0, c: 0 }, e: { r: 0, c: 7 } },
        ]
        const wscols = [
            { wch: 6 },
            { wch: 10 },
            { wch: 26 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
        ]
        ws['!merges'] = merge
        ws['!cols'] = wscols
        XLSX.utils.book_append_sheet(
            wb,
            ws,
            `รายงานสินค้าคงเหลือ`,
        )

        XLSX.writeFile(wb, fileName)
        setTimeout(() => {
            exportLoading.value = false
        }, 1500)
    }

    onMounted(() => {
        getStores()
    })


    return {
        textSearch,
        headers,
        itemsPerPage,
        isAdd,
        isShowDetail,
        isEdit,
        dataEdit,
        footer,
        isUpdateStatus,
        status,
        dataList,
        loading,
        storeList,
        storeSelection,
        exportLoading,
        exportExcel,
        getProducts,
    }
}